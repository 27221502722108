.Title-white { 
    color: transparent;
    -webkit-text-stroke-color: #ffffffdd;
    -webkit-text-stroke-width: 2px;
}

.Title-black { 
    color: transparent;
    -webkit-text-stroke-color: #000000d5;
    -webkit-text-stroke-width: 2px;
}

.Title-letter {
    transition: color 2s ease-in-out;
}

.Title-letter:hover {
    transition-duration: .15s;
}

.Title-letter-black:hover {
    color: #000000d5;
}

.Title-letter-white:hover {
    color: #ffffffd5;
}