h1 {
    color: transparent;

    -webkit-text-stroke-color: #000000d5;
    -webkit-text-stroke-width: 2px;
}

.animate {
    animation: animateFont 12s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
}

.animate:nth-of-type(1) {
    animation-delay: 0s;
}

.animate:nth-of-type(2) {
    animation-delay: 1s;
}

.animate:nth-of-type(3) {
    animation-delay: 2s;
}

.animate:nth-of-type(4) {
    animation-delay: 3s;
}

.animate:nth-of-type(5) {
    animation-delay: 4s;
}

.animate:nth-of-type(6) {
    animation-delay: 5s;
}

@keyframes animateFont {
    0% {
        color: transparent;
    }

    50% {
        color: #000000d5;
    }

    100% {
        color: transparent;
    }

}









:root {
    --startDashArray: 275;
    --endDashArray: 600;
    --startDashOffset: 300;
    --endDashOffset: 550;
}

.animateFace path {
    stroke: white;
    stroke-width: 2px;
    stroke-dasharray: var(--startDashArray);
    stroke-dashoffset: var(--startDashOffset);
    /* fill-opacity: 0; */
    animation: faceAnimation 8s ease-in-out infinite !important;
}


@keyframes faceAnimation {
    0% {
        stroke-dasharray: var(--startDashArray);
        stroke-dashoffset: var(--startDashOffset);
    }

    50% {
        stroke-dasharray: var(--endDashArray);
        stroke-dashoffset: var(--endDashOffset);
    }

    100% {
        stroke-dasharray: var(--startDashArray);
        stroke-dashoffset: var(--startDashOffset);
    }

}