.highlight {
    /* font-size: 1.5rem; */
    background-color: #000000d5;
    padding: 1px 5px;
    border-radius: 5px;
    color: #ffffff;
}

.float{
    float: left !important;
}