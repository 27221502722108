.custom-link path {
    stroke-opacity: 1;
    stroke-width: 2px;
    stroke: black;
    stroke-dasharray: 1100;
    stroke-dashoffset: 550;
    transition: stroke-dasharray ease-in-out .3s, stroke-dashoffset ease-in-out .3s;
}

.custom-link.stroke-white path,
.custom-link.white path {
    stroke: white;
}

.custom-link:hover path {
    stroke-dasharray: 1100;
    stroke-dashoffset: 100; 
}

.animation-delay-1{animation-delay: .7s !important;}
.animation-delay-2{animation-delay: 1.4s !important;}
.animation-delay-3{animation-delay: 2.1s !important;}
.animation-delay-4{animation-delay: 2.8s !important;}
.animation-delay-5{animation-delay: 3.5s !important;}

@media screen and (min-width: 500px) {
    .custom-link {
        animation: float 8s linear infinite;
    }
}

@keyframes float {
    0% {
        transform: translate3d(0, 0px, 0);
    }

    25% {
        transform: translate3d(0, -10px, 0);
    }



    75% {
        transform: translate3d(0, 10px, 0);
    }

    100% {
        transform: translate3d(0, 0px, 0);
    }
}