.AboutPair svg path {
    stroke: #000000d5;
    stroke-width: 2px;
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
    fill-opacity: 0;
    animation: live 15s ease-in-out infinite;
}






@keyframes live {
    0% {
        stroke-dasharray: 100;
        stroke-dashoffset: 1;
        fill-opacity: 0.9;
    }

    50% {
        stroke-dasharray: 200;
        stroke-dashoffset: 1500;
        fill-opacity: 1;
    }

    100% {
        stroke-dasharray: 100;
        stroke-dashoffset: 1;
        fill-opacity: 0.9;
    }
}