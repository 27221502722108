.loader-container .box svg { 
    margin: 16px;    
}

.loader-container {
    position: relative;
    height: 120px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.loader-container .center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 140px;
    height: 140px;
    border-radius: 100%;
    background-color: #fff;
    border: 9px solid #DEE1E4;
    overflow: hidden;
}

.loader-container::before {
    content: "";
    position: absolute;
    top: 210px;
    left: 26px;
    width: 100px;
    height: 32px;
    border-radius: 100%;
    background-color: rgba(222, 225, 228, 0.4);
}

.loader-container .wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.loader-container span::before {
    content: "";
    position: absolute;
    width: 50px;
    height: 6px;
    background-color: #DEE1E4;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.loader-container span::after {
    content: "";
    position: absolute;
    left: 30px;
    top: 20px;
    width: 36px;
    height: 12px;
    background-color: #DEE1E4;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-radius: 0 4px 4px 0;
}

.loader-container .box-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.loader-container .box-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

/* animation */

@-webkit-keyframes rowup-1 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    100% {
        -webkit-transform: translate3d(50%, 0, 0);
        transform: translate3d(50%, 0, 0);
    }
}

@keyframes rowup-1 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    100% {
        -webkit-transform: translate3d(50%, 0, 0);
        transform: translate3d(50%, 0, 0);
    }
}

@-webkit-keyframes rowup-2 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    100% {
        -webkit-transform: translate3d(50%, 0, 0);
        transform: translate3d(50%, 0, 0);
    }
}

@keyframes rowup-2 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    100% {
        -webkit-transform: translate3d(50%, 0, 0);
        transform: translate3d(50%, 0, 0);
    }
}

@-webkit-keyframes cloud-loop {
    0% {
        -webkit-transform: translate(0, 15px);
        transform: translate(0, 15px);
    }

    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@-webkit-keyframes shadow-loop {
    0% {
        -webkit-transform: translate(0, -35px) scale(1.15, 0.25);
        transform: translate(0, -35px) scale(1.15, 0.25);
    }

    100% {
        -webkit-transform: translate(0, -35px) scale(1, 0.25);
        transform: translate(0, -35px) scale(1, 0.25);
    }
}

@keyframes shadow-loop {
    0% {
        -webkit-transform: translate(0, -35px) scale(1.15, 0.25);
        transform: translate(0, -35px) scale(1.15, 0.25);
    }

    100% {
        -webkit-transform: translate(0, -35px) scale(1, 0.25);
        transform: translate(0, -35px) scale(1, 0.25);
    }
}

.loader-container .box-1 {
    -webkit-animation: 1s rowup-1 linear infinite normal;
    animation: 1s rowup-1 linear infinite normal;
}

.loader-container .box-2 {
    -webkit-animation: 1s rowup-2 linear infinite normal;
    animation: 1s rowup-2 linear infinite normal;
}

.loader-container .center {
    -webkit-animation-name: cloud-loop;
    animation-name: cloud-loop;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

.loader-container span {
    position: relative;
    left: 112px;
    top: 60px;
    -webkit-animation-name: cloud-loop;
    animation-name: cloud-loop;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

.loader-container::before {
    -webkit-animation-name: shadow-loop;
    animation-name: shadow-loop;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}