.SkillItem {
    animation: wiggle 10s ease-in-out infinite;
}

.SkillItem:hover {
    animation-play-state: paused;
}

.SkillItem-info {
    /* position: absolute; */
    color: #ffffffdd;
    font-size: 20px;
}



.SkillItem-img-container::after {
    content: 'More';
    height: 100%;
    width: 50px;
    z-index: -1;
    border-radius: 15px 0 0 15px;
    font-size: 19px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    top: 0;
    padding-left: 5px;
    left: 5px;
    writing-mode: vertical-rl;
    text-align: center;

    color: #000000d5;
    position: absolute;
    background-color: #ffffffdd;
    opacity: 0;
    /* transform: rotate(180deg); */
    transition: opacity .25s ease-in-out, left .25s ease-in-out;
}

.SkillItem-img-container:hover::after {
    opacity: 1;
    left: -2px;
}

/* .SkillItem img {
    position: relative;
    height: 100%;
    filter: grayscale(0.5);
    transition: filter .25s ease-in-out;
    transition-delay: 1s;
    cursor: pointer;
} */

.SkillItem img {

    cursor: pointer;
}

.SkillItem:hover img {
    transition-delay: 0s !important;
    /* filter: grayscale(0%); */
}

@keyframes wiggle {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(-7deg);
    }

    50% {
        transform: rotate(13deg);
    }

    75% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(0deg);
    }
}


/* generated delays */
.SkillItem:nth-of-type(1) {
    animation-delay: 1292ms;
}

.SkillItem:nth-of-type(2) {
    animation-delay: 263ms;
}

.SkillItem:nth-of-type(3) {
    animation-delay: 483ms;
}

.SkillItem:nth-of-type(4) {
    animation-delay: 376ms;
}

.SkillItem:nth-of-type(5) {
    animation-delay: 1297ms;
}

.SkillItem:nth-of-type(6) {
    animation-delay: 966ms;
}

.SkillItem:nth-of-type(7) {
    animation-delay: 1366ms;
}

.SkillItem:nth-of-type(8) {
    animation-delay: 1162ms;
}

.SkillItem:nth-of-type(9) {
    animation-delay: 357ms;
}

.SkillItem:nth-of-type(10) {
    animation-delay: 622ms;
}

.SkillItem:nth-of-type(11) {
    animation-delay: 1435ms;
}

.SkillItem:nth-of-type(12) {
    animation-delay: 10ms;
}

.SkillItem:nth-of-type(13) {
    animation-delay: 244ms;
}

.SkillItem:nth-of-type(14) {
    animation-delay: 1106ms;
}

.SkillItem:nth-of-type(15) {
    animation-delay: 6ms;
}

.SkillItem:nth-of-type(16) {
    animation-delay: 1411ms;
}

.SkillItem:nth-of-type(17) {
    animation-delay: 778ms;
}

.SkillItem:nth-of-type(18) {
    animation-delay: 619ms;
}